
import React from 'react'
const Apple = () => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="164.8566px" height="40px" viewBox="0 0 164.8566 40" enable-background="new 0 0 164.8566 40" xml-space="preserve">
<g id="US">
	<path fill="#A6A6A6" d="M155.3273,0H9.5347C9.168,0,8.8057,0,8.4399,0.002C8.1338,0.0039,7.8301,0.0098,7.521,0.0146
		C6.8555,0.0312,6.1816,0.0723,5.5171,0.1914c-0.6694,0.1172-1.2915,0.3174-1.9009,0.627C3.0176,1.125,2.4707,1.5234,1.9976,1.9971
		C1.519,2.4707,1.1226,3.0195,0.8193,3.6182c-0.311,0.6084-0.5083,1.2334-0.625,1.9033c-0.1206,0.6621-0.1621,1.332-0.1792,2.002
		C0.0059,7.8301,0.0049,8.1377,0,8.4443c0,0.3623,0,0.7256,0,1.0918v20.9287c0,0.3692,0,0.7305,0,1.0938
		c0.0049,0.3105,0.0059,0.6113,0.0151,0.9219c0.0171,0.6699,0.0586,1.3398,0.1792,2.0019c0.1167,0.6699,0.314,1.2979,0.625,1.9043
		c0.3032,0.5957,0.6997,1.1446,1.1782,1.6143c0.4731,0.4775,1.02,0.875,1.6187,1.1787c0.6094,0.3125,1.2314,0.5098,1.9009,0.6308
		c0.6645,0.1192,1.3384,0.1583,2.0039,0.1768c0.3091,0.0068,0.6128,0.0107,0.9189,0.0107C8.8057,40,9.168,40,9.5347,40h145.7927
		c0.3594,0,0.7246,0,1.084-0.002c0.3047,0,0.6172-0.0039,0.9219-0.0107c0.6699-0.0185,1.3418-0.0576,2-0.1768
		c0.6699-0.121,1.2929-0.3183,1.9082-0.6308c0.5976-0.3037,1.1445-0.7012,1.6172-1.1787c0.4765-0.4697,0.873-1.0186,1.1816-1.6143
		c0.3066-0.6064,0.5059-1.2344,0.6191-1.9043c0.1231-0.6621,0.1621-1.332,0.1856-2.0019c0.0039-0.3106,0.0039-0.6114,0.0039-0.9219
		c0.0078-0.3633,0.0078-0.7246,0.0078-1.0938V9.5361c0-0.3662,0-0.7295-0.0078-1.0918c0-0.3066,0-0.6143-0.0039-0.9209
		c-0.0235-0.6699-0.0625-1.3398-0.1856-2.002c-0.1132-0.6699-0.3125-1.2949-0.6191-1.9033
		c-0.3086-0.5986-0.7051-1.1475-1.1816-1.6211c-0.4727-0.4736-1.0196-0.8721-1.6172-1.1787
		c-0.6153-0.3096-1.2383-0.5098-1.9082-0.627c-0.6582-0.1191-1.3301-0.1602-2-0.1768c-0.3047-0.0049-0.6172-0.0107-0.9219-0.0127
		C156.0519,0,155.6867,0,155.3273,0L155.3273,0z"/>
	<path d="M8.4448,39.125c-0.3047,0-0.602-0.0039-0.9043-0.0107c-0.5591-0.0157-1.2222-0.0469-1.8691-0.1631
		c-0.6104-0.1104-1.1528-0.2901-1.6567-0.5479c-0.5215-0.2646-0.9902-0.6064-1.397-1.0166
		c-0.4141-0.4062-0.7544-0.873-1.0205-1.3965c-0.2593-0.5048-0.4365-1.0468-0.543-1.6572c-0.1221-0.6728-0.1533-1.3555-0.1665-1.875
		c-0.0063-0.2109-0.0146-0.9131-0.0146-0.9131V8.4443c0,0,0.0088-0.6914,0.0146-0.8945c0.0132-0.5239,0.0444-1.206,0.1655-1.8721
		C1.1602,5.064,1.3374,4.5225,1.5967,4.0156C1.8623,3.4912,2.2031,3.022,2.6118,2.6177c0.4136-0.4141,0.8843-0.7568,1.4023-1.0225
		c0.5137-0.2607,1.0552-0.439,1.6533-0.5439c0.6733-0.1206,1.356-0.1509,1.8755-0.1641L8.4453,0.875h147.9611l0.9131,0.0127
		c0.5127,0.0127,1.1953,0.043,1.8584,0.1626c0.6025,0.1055,1.1475,0.2847,1.6709,0.5479c0.5127,0.2627,0.9824,0.6055,1.3916,1.0156
		c0.4092,0.4062,0.7519,0.8779,1.0234,1.4043c0.2578,0.5112,0.4336,1.0527,0.5352,1.6489c0.1162,0.6309,0.1523,1.2788,0.1738,1.8872
		c0.0029,0.2832,0.0029,0.5874,0.0029,0.8901c0.0079,0.375,0.0079,0.7319,0.0079,1.0918v20.9287c0,0.3633,0,0.7178-0.0079,1.0752
		c0,0.3252,0,0.6231-0.0039,0.9297c-0.0205,0.5889-0.0566,1.2364-0.1709,1.8535c-0.1035,0.6133-0.2793,1.1553-0.54,1.67
		c-0.2695,0.5195-0.6123,0.9892-1.0156,1.3857c-0.4131,0.418-0.8819,0.7588-1.3994,1.0225
		c-0.5186,0.2636-1.0479,0.4384-1.668,0.5498c-0.6406,0.1162-1.3037,0.1474-1.8692,0.1631
		c-0.2929,0.0068-0.5996,0.0107-0.8974,0.0107l-1.084,0.002L8.4448,39.125z"/>
	<g id="XMLID_632_">
		<linearGradient id="XMLID_2_" gradientUnits="userSpaceOnUse" x1="20.0983" y1="7.4183" x2="20.0983" y2="32.5813">
			<stop  offset="0" style={{stopColor:"#F452FF"}}/>
			<stop  offset="1" style={{stopColor:"#832BC1"}}/>
		</linearGradient>
		<path id="XMLID_662_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_2_)" d="M32.1821,29.2281
			c-0.2446,0.6213-0.809,1.4961-1.6094,2.1123c-0.4621,0.3559-1.0138,0.6961-1.7716,0.9206
			c-0.8078,0.2394-1.8028,0.3203-3.0412,0.3203H14.4366c-1.2383,0-2.2333-0.0809-3.0412-0.3203
			c-0.7578-0.2245-1.3094-0.5647-1.7716-0.9206c-0.8003-0.6163-1.3648-1.491-1.6094-2.1123
			c-0.4927-1.2518-0.4977-2.6721-0.4977-3.5666l0,0V14.3381l0,0c0-0.8945,0.005-2.3148,0.4977-3.5666
			c0.2446-0.6213,0.809-1.4961,1.6094-2.1123c0.4621-0.3559,1.0138-0.6961,1.7716-0.9206c0.8078-0.2394,1.8028-0.3203,3.0412-0.3203
			l0,0H25.76l0,0c1.2383,0,2.2333,0.0809,3.0412,0.3203c0.7578,0.2245,1.3094,0.5648,1.7716,0.9206
			c0.8003,0.6163,1.3648,1.491,1.6094,2.1123c0.4927,1.2518,0.4977,2.6721,0.4977,3.5666v11.3234
			C32.6798,26.5559,32.6748,27.9762,32.1821,29.2281z"/>
		<path id="XMLID_660_" fill-rule="evenodd" clip-rule="evenodd" fill="none" d="M32.1821,29.2281
			c-0.2446,0.6213-0.809,1.4961-1.6094,2.1123c-0.4621,0.3559-1.0138,0.6961-1.7716,0.9206
			c-0.8078,0.2394-1.8028,0.3203-3.0412,0.3203H14.4366c-1.2383,0-2.2333-0.0809-3.0412-0.3203
			c-0.7578-0.2245-1.3094-0.5647-1.7716-0.9206c-0.8003-0.6163-1.3648-1.491-1.6094-2.1123
			c-0.4927-1.2518-0.4977-2.6721-0.4977-3.5666l0,0V14.3381l0,0c0-0.8945,0.005-2.3148,0.4977-3.5666
			c0.2446-0.6213,0.809-1.4961,1.6094-2.1123c0.4621-0.3559,1.0138-0.6961,1.7716-0.9206c0.8078-0.2394,1.8028-0.3203,3.0412-0.3203
			l0,0H25.76l0,0c1.2383,0,2.2333,0.0809,3.0412,0.3203c0.7578,0.2245,1.3094,0.5648,1.7716,0.9206
			c0.8003,0.6163,1.3648,1.491,1.6094,2.1123c0.4927,1.2518,0.4977,2.6721,0.4977,3.5666v11.3234
			C32.6798,26.5559,32.6748,27.9762,32.1821,29.2281z"/>
		<g id="XMLID_648_">
			<g>
				<path fill="#FFFFFF" d="M21.9232,21.8815c-0.3776-0.3984-1.0408-0.6538-1.8237-0.6538c-0.783,0-1.4462,0.2554-1.8238,0.6538
					c-0.197,0.2079-0.3012,0.421-0.334,0.7246c-0.0636,0.5879-0.0279,1.0941,0.041,1.9034c0.0656,0.7712,0.1902,1.7996,0.3528,2.847
					c0.1157,0.7454,0.2097,1.1477,0.2953,1.4358c0.1388,0.4669,0.6575,0.8752,1.4687,0.8752c0.8111,0,1.3298-0.4084,1.4686-0.8752
					c0.0856-0.2881,0.1796-0.6904,0.2953-1.4358c0.1626-1.0474,0.2872-2.0758,0.3528-2.847
					c0.0689-0.8094,0.1046-1.3156,0.041-1.9034C22.2244,22.3026,22.1202,22.0894,21.9232,21.8815z M18.0421,18.3342
					c0,1.1373,0.922,2.0593,2.0593,2.0593c1.1374,0,2.0594-0.922,2.0594-2.0593c0-1.1374-0.922-2.0594-2.0594-2.0594
					C18.9641,16.2748,18.0421,17.1968,18.0421,18.3342z M20.0759,10.2072c-4.8163,0.0137-8.7628,3.921-8.8224,8.737
					c-0.0484,3.9013,2.442,7.2385,5.9195,8.4617c0.0844,0.0297,0.1701-0.0404,0.1568-0.1289
					c-0.0456-0.3029-0.0886-0.6073-0.1281-0.9066c-0.014-0.1058-0.0814-0.197-0.1791-0.2397
					c-2.7486-1.2008-4.6679-3.9574-4.6371-7.1505c0.0404-4.1904,3.4622-7.6066,7.6526-7.6405c4.2839-0.0346,7.78,3.4402,7.78,7.7162
					c0,3.1611-1.9109,5.8839-4.6381,7.0751c-0.0977,0.0427-0.1647,0.1342-0.1786,0.2399c-0.0395,0.2992-0.0825,0.6035-0.128,0.9061
					c-0.0134,0.0885,0.0723,0.1586,0.1567,0.129c3.4443-1.2115,5.9202-4.4968,5.9202-8.35
					C28.9501,14.1681,24.9668,10.1932,20.0759,10.2072z M19.916,14.2667c2.7303-0.1057,4.9851,2.0847,4.9851,4.7919
					c0,1.3768-0.5833,2.6198-1.5156,3.4952c-0.0791,0.0742-0.1216,0.1796-0.1155,0.2879c0.0186,0.3293,0.0118,0.6493-0.0101,1.0107
					c-0.0059,0.0963,0.1021,0.1578,0.1818,0.1035c1.5634-1.0684,2.5917-2.8649,2.5917-4.8973c0-3.3464-2.7874-6.0541-6.1623-5.9233
					c-3.1364,0.1216-5.6527,2.7075-5.6929,5.8461c-0.0264,2.0642,1.0082,3.8929,2.5917,4.9747
					c0.0795,0.0543,0.1872-0.0073,0.1813-0.1035c-0.022-0.3616-0.0287-0.6816-0.0102-1.0109
					c0.0061-0.1082-0.0363-0.2136-0.1152-0.2877c-0.9614-0.9026-1.5518-2.1961-1.5142-3.6241
					C15.3778,16.4169,17.4039,14.364,19.916,14.2667z"/>
			</g>
		</g>
	</g>
	<g>
		<path fill="#FFFFFF" d="M46.223,27.8223h-4.7334l-1.1367,3.3564H38.348l4.4834-12.4189h2.083l4.4834,12.4189h-2.0391
			L46.223,27.8223z M41.9798,26.2734h3.752l-1.8496-5.4482h-0.0518L41.9798,26.2734z"/>
		<path fill="#FFFFFF" d="M59.0804,26.6523c0,2.8135-1.5059,4.6211-3.7783,4.6211c-1.29,0-2.3145-0.5771-2.8486-1.584h-0.043v4.4844
			h-1.8584V22.125h1.7988v1.5059h0.0342c0.5166-0.9717,1.6182-1.6006,2.8828-1.6006
			C57.5657,22.0303,59.0804,23.8467,59.0804,26.6523z M57.1702,26.6523c0-1.833-0.9473-3.0381-2.3926-3.0381
			c-1.4199,0-2.375,1.2305-2.375,3.0381c0,1.8242,0.9551,3.0459,2.375,3.0459C56.223,29.6982,57.1702,28.502,57.1702,26.6523z"/>
		<path fill="#FFFFFF" d="M69.0452,26.6523c0,2.8135-1.5059,4.6211-3.7783,4.6211c-1.29,0-2.3144-0.5771-2.8486-1.584h-0.043v4.4844
			h-1.8584V22.125h1.7988v1.5059h0.0342c0.5166-0.9717,1.6182-1.6006,2.8828-1.6006
			C67.5306,22.0303,69.0452,23.8467,69.0452,26.6523z M67.1351,26.6523c0-1.833-0.9473-3.0381-2.3926-3.0381
			c-1.4199,0-2.375,1.2305-2.375,3.0381c0,1.8242,0.9551,3.0459,2.375,3.0459C66.1878,29.6982,67.1351,28.502,67.1351,26.6523z"/>
		<path fill="#FFFFFF" d="M70.5501,18.7598h1.8594v12.4189h-1.8594V18.7598z"/>
		<path fill="#FFFFFF" d="M82.0853,28.5195c-0.25,1.6436-1.8506,2.7715-3.8984,2.7715c-2.6338,0-4.2686-1.7646-4.2686-4.5957
			c0-2.8398,1.6436-4.6816,4.1904-4.6816c2.5049,0,4.0801,1.7207,4.0801,4.4658v0.6367h-6.3945v0.1123
			c0,1.5488,0.9727,2.5645,2.4355,2.5645c1.0322,0,1.8418-0.4902,2.0908-1.2734H82.0853z M75.803,25.8174h4.5264
			c-0.043-1.3857-0.9297-2.2979-2.2207-2.2979C76.8265,23.5195,75.8978,24.4492,75.803,25.8174z"/>
		<path fill="#FFFFFF" d="M92.0872,18.7598c2.4092,0,4.0879,1.6611,4.0879,4.0801c0,2.4268-1.7129,4.0957-4.1484,4.0957h-2.668
			v4.2432H87.431V18.7598H92.0872z M89.3587,25.3184h2.2119c1.6777,0,2.6328-0.9043,2.6328-2.4697
			c0-1.5664-0.9551-2.4624-2.624-2.4624h-2.2207V25.3184z"/>
		<path fill="#FFFFFF" d="M96.8538,26.6523c0-2.8486,1.6777-4.6387,4.2939-4.6387c2.625,0,4.2949,1.79,4.2949,4.6387
			c0,2.8564-1.6611,4.6387-4.2949,4.6387C98.5149,31.291,96.8538,29.5088,96.8538,26.6523z M103.5491,26.6523
			c0-1.9541-0.8955-3.1074-2.4014-3.1074s-2.4014,1.1621-2.4014,3.1074c0,1.9619,0.8955,3.1064,2.4014,3.1064
			S103.5491,28.6143,103.5491,26.6523z"/>
		<path fill="#FFFFFF" d="M106.7474,26.6523c0-2.7969,1.54-4.6221,3.7861-4.6221c1.2998,0,2.3242,0.6025,2.8398,1.6006h0.0352
			v-4.8711h1.8672v12.4189h-1.8076v-1.54h-0.0342c-0.5332,1.0322-1.5664,1.6348-2.8828,1.6348
			C108.2874,31.2734,106.7474,29.4492,106.7474,26.6523z M108.6487,26.6523c0,1.8584,0.9473,3.0459,2.3926,3.0459
			c1.4287,0,2.3838-1.2051,2.3838-3.0459c0-1.8252-0.9551-3.0381-2.3838-3.0381C109.596,23.6143,108.6487,24.8105,108.6487,26.6523z
			"/>
		<path fill="#FFFFFF" d="M123.1839,25.2402c-0.1631-0.9551-0.9121-1.6689-2.1338-1.6689c-1.4287,0-2.376,1.1963-2.376,3.0811
			c0,1.9277,0.9561,3.0889,2.3926,3.0889c1.1533,0,1.9111-0.5762,2.1172-1.626h1.79c-0.2061,1.9014-1.7295,3.1758-3.9238,3.1758
			c-2.582,0-4.2686-1.7646-4.2686-4.6387c0-2.8145,1.6865-4.6387,4.251-4.6387c2.3232,0,3.7695,1.4629,3.9248,3.2266H123.1839z"/>
		<path fill="#FFFFFF" d="M126.1126,28.6055c0-1.583,1.2129-2.5391,3.3643-2.668l2.4785-0.1377v-0.6885
			c0-1.0068-0.6621-1.5752-1.7891-1.5752c-1.0332,0-1.7559,0.4912-1.9023,1.2744h-1.7383c0.0518-1.6357,1.5742-2.7969,3.6914-2.7969
			c2.1602,0,3.5889,1.1787,3.5889,2.96v6.2051h-1.7812v-1.4893h-0.043c-0.5254,1.0068-1.6699,1.6445-2.8574,1.6445
			C127.3519,31.334,126.1126,30.2324,126.1126,28.6055z M131.9554,27.7881v-0.6973l-2.2285,0.1377
			c-1.1104,0.0693-1.7383,0.5508-1.7383,1.3252c0,0.792,0.6543,1.3086,1.6524,1.3086
			C130.9407,29.8623,131.9554,28.9668,131.9554,27.7881z"/>
		<path fill="#FFFFFF" d="M138.9935,22.0225c2.0059,0,3.4424,1.1094,3.4854,2.71h-1.7471c-0.0771-0.7998-0.7568-1.29-1.79-1.29
			c-1.0068,0-1.6777,0.4639-1.6777,1.1699c0,0.542,0.4473,0.9033,1.3857,1.1357l1.5234,0.3535
			c1.8242,0.4385,2.5127,1.1094,2.5127,2.4355c0,1.6348-1.5488,2.7539-3.7607,2.7539c-2.1348,0-3.5713-1.0938-3.709-2.7461h1.8408
			c0.1299,0.8691,0.8262,1.334,1.9541,1.334c1.1104,0,1.8076-0.4561,1.8076-1.1787c0-0.5596-0.3447-0.8604-1.291-1.1016
			l-1.6182-0.3955c-1.6357-0.3965-2.4619-1.2314-2.4619-2.4873C135.4476,23.1152,136.8851,22.0225,138.9935,22.0225z"/>
		<path fill="#FFFFFF" d="M146.7239,19.9819v2.1431h1.7217v1.4717h-1.7217v4.9912c0,0.7754,0.3447,1.1367,1.1016,1.1367
			c0.1895,0,0.4912-0.0264,0.6113-0.043v1.4629c-0.2061,0.0518-0.6191,0.0859-1.0322,0.0859c-1.833,0-2.5479-0.6885-2.5479-2.4443
			v-5.1895h-1.3164V22.125h1.3164v-2.1431H146.7239z"/>
		<path fill="#FFFFFF" d="M153.4427,22.0225c2.0059,0,3.4424,1.1094,3.4854,2.71h-1.7471c-0.0771-0.7998-0.7568-1.29-1.79-1.29
			c-1.0068,0-1.6777,0.4639-1.6777,1.1699c0,0.542,0.4473,0.9033,1.3857,1.1357l1.5234,0.3535
			c1.8242,0.4385,2.5127,1.1094,2.5127,2.4355c0,1.6348-1.5488,2.7539-3.7607,2.7539c-2.1348,0-3.5713-1.0938-3.709-2.7461h1.8408
			c0.1299,0.8691,0.8262,1.334,1.9541,1.334c1.1104,0,1.8076-0.4561,1.8076-1.1787c0-0.5596-0.3447-0.8604-1.291-1.1016
			l-1.6182-0.3955c-1.6357-0.3965-2.4619-1.2314-2.4619-2.4873C149.8968,23.1152,151.3343,22.0225,153.4427,22.0225z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M42.9578,14.6611h-3.7217V8.6943h0.9263v5.1147h2.7954V14.6611z"/>
		<path fill="#FFFFFF" d="M44.0345,8.8267c0-0.3105,0.2441-0.5459,0.5747-0.5459c0.3311,0,0.5747,0.2354,0.5747,0.5459
			c0,0.3057-0.2437,0.5415-0.5747,0.5415C44.2786,9.3682,44.0345,9.1323,44.0345,8.8267z M44.1668,10.1582h0.8848v4.5029h-0.8848
			V10.1582z"/>
		<path fill="#FFFFFF" d="M48.1307,10.0713c1.0132,0,1.6748,0.4712,1.7612,1.2651h-0.8516
			c-0.083-0.3306-0.4053-0.5415-0.9097-0.5415c-0.4966,0-0.8726,0.2354-0.8726,0.5869c0,0.269,0.2275,0.4385,0.7153,0.5503
			l0.7485,0.1733c0.856,0.1987,1.2568,0.5669,1.2568,1.2285c0,0.8477-0.7896,1.4141-1.8647,1.4141
			c-1.0713,0-1.77-0.4839-1.8486-1.2817h0.8892c0.1118,0.3472,0.4424,0.562,0.98,0.562c0.5542,0,0.9473-0.248,0.9473-0.6079
			c0-0.2686-0.2109-0.4424-0.6621-0.5498l-0.7856-0.1821c-0.856-0.2026-1.2529-0.5869-1.2529-1.2568
			C46.3812,10.6333,47.1131,10.0713,48.1307,10.0713z"/>
		<path fill="#FFFFFF" d="M52.346,9.0371v1.1416h0.9756v0.7485H52.346v2.3154c0,0.4717,0.1943,0.6782,0.6367,0.6782
			c0.1362,0,0.2148-0.0083,0.3389-0.0205v0.7402c-0.1445,0.0244-0.3101,0.0454-0.4839,0.0454c-0.9883,0-1.3809-0.3477-1.3809-1.2158
			v-2.543h-0.7153v-0.7485h0.7153V9.0371H52.346z"/>
		<path fill="#FFFFFF" d="M58.2532,13.4453c-0.2026,0.8066-0.9224,1.3027-1.9517,1.3027c-1.2905,0-2.0801-0.8848-2.0801-2.3242
			c0-1.439,0.8062-2.3525,2.0757-2.3525c1.2529,0,2.0098,0.856,2.0098,2.27v0.3101h-3.1802v0.0498
			c0.0293,0.7896,0.4883,1.29,1.1992,1.29c0.5376,0,0.9058-0.1943,1.0713-0.5459H58.2532z M55.1268,11.9941h2.2744
			c-0.0205-0.707-0.4507-1.1665-1.1079-1.1665C55.6356,10.8276,55.1766,11.291,55.1268,11.9941z"/>
		<path fill="#FFFFFF" d="M59.4993,10.1582h0.856v0.7153h0.0664c0.2188-0.5005,0.6655-0.8022,1.3438-0.8022
			c1.0049,0,1.5591,0.6035,1.5591,1.6748v2.915h-0.8892v-2.6919c0-0.7236-0.3145-1.0835-0.9717-1.0835
			c-0.6577,0-1.0752,0.4385-1.0752,1.1411v2.6343h-0.8892V10.1582z"/>
		<path fill="#FFFFFF" d="M66.9183,12.4077c0-1.4517,0.8105-2.3364,2.1255-2.3364c1.3105,0,2.1211,0.8848,2.1211,2.3364
			c0,1.4595-0.8062,2.3403-2.1211,2.3403C67.7244,14.748,66.9183,13.8672,66.9183,12.4077z M70.2513,12.4077
			c0-0.9761-0.4385-1.5469-1.2075-1.5469c-0.7734,0-1.2075,0.5708-1.2075,1.5469c0,0.9839,0.4341,1.5503,1.2075,1.5503
			C69.8128,13.958,70.2513,13.3877,70.2513,12.4077z"/>
		<path fill="#FFFFFF" d="M72.3572,10.1582h0.856v0.7153h0.0664c0.2192-0.5005,0.6655-0.8022,1.3438-0.8022
			c1.0049,0,1.5591,0.6035,1.5591,1.6748v2.915h-0.8892v-2.6919c0-0.7236-0.3145-1.0835-0.9717-1.0835
			c-0.6577,0-1.0752,0.4385-1.0752,1.1411v2.6343h-0.8892V10.1582z"/>
	</g>
</g>
<g>
</g>
</svg>)
export default Apple
