/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import SEO from '../components_v2/seo'
import {
  Header,
  AuthorData,
  ArticleImage,
  HeaderContentWrapper,
  PostTitle,
  PostDate,
  Article,
  PostTags,
  PostTag,
  AuthorInfoContainer,
  TagsContainer,
  ReadTime,
  ArticleCard,
  ArticleSection,
  AudioPlayer,
  IconsWrapper,
} from '../styles/BlogTemplate.style'
import LayoutV2 from '../components_v2/Layout'

import Spotify from '../static/Spotify_podcast.svg'
import Google from '../static/google_podcast.svg'
import Apple from '../static/apple_podcast.svg'
import Youtube from '../static/Youtube.svg'
/*
<IconsWrapper>
       <Link to="https://masgaz.com">
       <Spotify/>
       </Link>
       <Google/>
       <Apple/>
       <Youtube/>
    </IconsWrapper>
*/

// Load the code content themes
deckDeckGoHighlightElement()

const PodcastTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <LayoutV2>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        keywords={frontmatter.keywords}
        location={`https://zaat.dev/podcast/${frontmatter.slug}`}
        image={frontmatter.image.childImageSharp.fluid.src}
      />
      <ArticleCard>
        <Header>
          <HeaderContentWrapper>
            <PostTitle>{frontmatter.title}</PostTitle>
            <ArticleImage>
              <Img fluid={frontmatter.image.childImageSharp.fluid} />
            </ArticleImage>
            <AuthorData>
              <AuthorInfoContainer>
                <PostDate>Published on: {frontmatter.date}</PostDate>
                <ReadTime>Running time: {Math.floor(frontmatter.duration / 60)} Minutes</ReadTime>
              </AuthorInfoContainer>
            </AuthorData>
            <TagsContainer>
              <PostTags>
                Tags :
                {frontmatter.keywords.split(', ').map(tag => (
                  <PostTag> #{tag} </PostTag>
                ))}
              </PostTags>
            </TagsContainer>
          </HeaderContentWrapper>
        </Header>
        <AudioPlayer controls>
          <source src={frontmatter.enclosure_url} type={frontmatter.enclosure_type} />
          Your browser does't support Audio!
        </AudioPlayer>
        <IconsWrapper>
          <Link to={frontmatter.spotify}>
            <Spotify />
          </Link>
          <Link to={frontmatter.google}>
            <Google />
          </Link>
          <Link to={frontmatter.apple}>
            <Apple />
          </Link>
          <Link to={frontmatter.youtube}>
            <Youtube />
          </Link>
        </IconsWrapper>
        <Article>
          <ArticleSection dangerouslySetInnerHTML={{ __html: html }} />
        </Article>
      </ArticleCard>
    </LayoutV2>
  )
}

export default PodcastTemplate
export const pageQuery = graphql`
  query PodcastPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        description
        keywords
        slug
        duration
        enclosure_url
        enclosure_type
        spotify
        apple
        youtube
        google
        image {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
