import React from 'react'
const Youtube = () => (
<svg xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="150px" height="90px" viewBox="0 0 150 90" xml-space="preserve">

<g transform="matrix(0.08984375 0 0 0.08984375 0 0)">
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M509 46.2C 509 46.2 506 25.2 496.8 16C 485.2 4 472.2 3.9 466.3 3.2C 423.6 0.1000001 359.5 0.1000001 359.5 0.1000001L359.5 0.1000001L359.4 0.1000001C 359.4 0.1000001 295.3 0.1000001 252.6 3.2C 246.7 3.9 233.6 4 222 16.1C 212.8 25.3 209.8 46.3 209.8 46.3C 209.8 46.3 206.7 70.9 206.7 95.5L206.7 95.5L206.7 118.6C 206.7 143.2 209.8 167.8 209.8 167.8C 209.8 167.8 212.8 188.8 222 198C 233.7 210.1 248.9 209.8 255.6 211C 280 213.4 359.3 214.1 359.3 214.1C 359.3 214.1 423.4 214 466.1 210.9C 472 210.2 485.1 210.1 496.7 198C 505.9 188.8 508.9 167.8 508.9 167.8C 508.9 167.8 512 143.2 512 118.6L512 118.6L512 95.4C 512.1 70.9 509 46.2 509 46.2z" stroke="none" fill="#000000" fill-rule="nonzero" />
    <path transform="matrix(2 0 0 2 0 170)"  d="M191.1 173.1L171.7 173.1L171.7 161.9C 164.3 170.4 158.1 174.6 151.3 174.6C 145.4 174.6 141.2 171.7 139.1 166.7C 137.9 163.6 137 158.7 137 151.7L137 151.7L137 69.8L156.4 69.8L156.4 146.5C 156.4 150.8 156.4 152.6 156.4 153.7C 156.8 156.6 158 157.6 160.6 157.6C 164.3 157.6 167.7 154.3 171.7 148.6L171.7 148.6L171.7 69.8L191.1 69.8L191.1 173.1z" stroke="none" fill="#000000" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M122.7 103.6C 122.7 92.6 120.5 84.3 116.6 79.1C 111.4 72 103.3 69.2 95.1 69.2C 85.8 69.2 78.8 72.1 73.6 79.1C 69.6 84.3 67.5 92.7 67.5 103.7L67.5 103.7L67.5 140.4C 67.5 151.3 69.4 158.9 73.4 164.1C 78.6 171.2 86.9 174.8 95.10001 174.8C 103.3 174.8 111.8 171.1 117 164.1C 120.9 158.9 122.7 151.3 122.7 140.4L122.7 140.4L122.7 103.6zM103.2 142.4C 104.2 152.6 101.1 157.7 95 157.7C 89 157.7 85.8 152.6 86.8 142.4L86.8 142.4L86.8 101.5C 85.8 91.3 88.9 86.49999 95 86.49999C 101 86.49999 104.2 91.3 103.2 101.5L103.2 101.5L103.2 142.4z" stroke="none" fill="#000000" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M46 116.9L46 173.1L25.6 173.1L25.6 116.9C 25.6 116.9 4.4 48.00001 0 35.10001L0 35.10001L21.5 35.10001L35.8 89L50.1 35.1L71.6 35.1L46 116.9z" stroke="none" fill="#000000" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M486.5 125L486.5 104.1C 486.5 93.2 484.4 85.2 480.5 80.1C 475.3 73.1 467.9 69.4 458.6 69.4C 449.2 69.4 441.7 73.1 436.4 80.1C 432.5 85.3 430.2 93.7 430.2 104.6L430.2 104.6L430.2 140.4C 430.2 151.2 432.8 158.9 436.6 164C 441.9 171 449.3 174.4 459 174.4C 468.7 174.4 476.3 170.7 481.4 163.4C 483.6 160.1 485.1 156.4 485.7 152.4C 485.9 150.6 486.3 146.4 486.3 140.3L486.3 140.3L486.3 137.4L466.9 137.4C 466.9 144.9 466.9 149.3 466.8 150.3C 465.8 155.2 463.0999 157.8 458.5 157.8C 452.2 157.8 449.5 153.1 449.5 143.6L449.5 143.6L449.5 125.2L486.5 125.2L486.5 125zM449.7 98.5C 449.7 89 452.4 86.3 458.5 86.3C 464.6 86.3 467.1 89.2 467.1 98.60001L467.1 98.60001L467.1 109.7L449.7 109.7L449.7 98.5z" stroke="none" fill="#FFFFFF" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M400.8 69.2C 394 69.2 387.2 73.39999 381.3 80.8L381.3 80.8L381.3 36.1L362.9 36.1L362.9 173.1L381.3 173.1L381.3 162.8C 387.4 170.5 394.2 174.5 400.8 174.5C 408.2 174.5 413.6 170.5 416 162.9C 417.2 158.6 418 151.9 418 142.5L418 142.5L418 101.6C 418 91.9 416.8 85.3 415.5 81.2C 413.1 73.5 408.1 69.2 400.8 69.2zM398.6 143.4C 398.6 153.6 396.1 157.5 390.8 157.5C 387.7 157.5 384.3 156 381.2 152.8L381.2 152.8L381.2 90.9C 384.3 87.8 387.7 86.2 390.8 86.2C 396.1 86.2 398.6 89.2 398.6 99.4L398.6 99.4L398.6 143.4z" stroke="none" fill="#FFFFFF" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M347.5 173.1L328.1 173.1L328.1 161.9C 320.7 170.4 314.5 174.6 307.7 174.6C 301.8 174.6 297.6 171.7 295.5 166.7C 294.3 163.6 293.4 158.7 293.4 151.7L293.4 151.7L293.4 70.9L312.8 70.9L312.8 146.5C 312.8 150.8 312.8 152.6 312.8 153.7C 313.2 156.6 314.4 157.6 317 157.6C 320.7 157.6 324.1 154.3 328.1 148.6L328.1 148.6L328.1 70.9L347.5 70.9L347.5 173.1z" stroke="none" fill="#FFFFFF" fill-rule="nonzero" />
	<g transform="matrix(2 0 0 2 0 170)">
	</g>
    <path transform="matrix(2 0 0 2 0 170)"  d="M273.9 173.1L253.5 173.1L253.5 55.5L232 55.5L232 36.1L296.4 36.1L296.4 55.5L273.9 55.5L273.9 173.1" stroke="none" fill="#FFFFFF" fill-rule="nonzero" />
</g>
</svg>)
    export default Youtube